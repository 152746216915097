import { FooterColumnStoryblok } from '@/shared/types/storyblok/storyblok-types';

import { useBreakpoints } from '@/shared/providers/Device';
import { StoryblokComponent } from '@storyblok/react';
import styles from '../Footer.module.scss';

type FooterRowProps = {
    blok: FooterColumnStoryblok;
};

const FooterRow = (blok: FooterRowProps) => {
    const { isExtraLarge, isLarge, isExtraSmall, isXXSmall } = useBreakpoints();
    const isMobile = !isExtraLarge && !isLarge;
    const isXsmall = isExtraSmall || isXXSmall;

    return (
        <div
            className={styles.row}
            style={{
                width: isMobile ? blok.blok.widthMobile + '%' : blok.blok.widthDesktop + '%',
                justifyContent: isMobile ? blok.blok.justifyMobile : blok.blok.justifyDesktop,
                flexWrap: blok.blok.wrap | isXsmall ? 'wrap' : 'nowrap',
                marginBottom: blok.blok.marginBottom + 'px',
            }}
            key={blok.blok._uid}
        >
            {blok.blok?.content?.map(nestedBlok => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
        </div>
    );
};

export default FooterRow;
